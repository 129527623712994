import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CompShowInicio from './components/inicio.js';
import CompShowLogin from './components/login';
import CompVenta from './components/venta';
import CompInventario from './components/inventario';
import CompCrearProducto from './components/crearProducto.js';
import CompCarrito from './components/carrito';
import CompCrearDescuento from './components/createDescuento';
import CompShowVentas from './components/showVentas';
import CajaManager from './components/caja/cajaManager';
import { SidebarProvider, useSidebar } from './components/sidebarContext';
import CompSidebar from './components/sideBar';
import HistorialCajas from './components/caja/historialCaja';
import { FaBars } from 'react-icons/fa'; // Importar un ícono

const ToggleButton = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <button
      onClick={toggleSidebar}
      className={`bg-gray-800 text-white p-2 fixed top-4 left-4 z-50 rounded transition-all duration-300 ${isSidebarOpen ? 'transform rotate-90' : ''}`}
      aria-label={isSidebarOpen ? 'Cerrar Sidebar' : 'Abrir Sidebar'} // Accesibilidad
    >
      <FaBars size={24} />
    </button>
  );
};

function App() {
  return (
    <BrowserRouter>
      <SidebarProvider>
        <div>
          <ToggleButton />
          <CompSidebar />
          <Routes>
            <Route path='/' element={<CompShowInicio />} />
            <Route path='/login' element={<CompShowLogin />} />
            <Route path='/venta' element={<CompVenta />} />
            <Route path='/inventario' element={<CompInventario />} />
            <Route path='/caja/todas' element={<HistorialCajas />} />
            <Route path='/caja' element={<CajaManager />} />
            <Route path='/crearProducto' element={<CompCrearProducto />} />
            <Route path='/vender' element={<CompCarrito />} />
            <Route path='/ventas' element={<CompShowVentas />} />
            <Route path='/crearDescuento' element={<CompCrearDescuento />} />
          </Routes>
        </div>
      </SidebarProvider>
    </BrowserRouter>
  );
}

export default App;
