import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CompCreateProv from './createProv';
import { FaTrash, FaPlus, FaTimes, FaSave } from 'react-icons/fa'; // Importamos íconos de react-icons

function CompEditProduct({ productId, onSave, handleCloseEdit }) {
    const [product, setProduct] = useState({
        nombre_producto: '',
        tipo_producto: '',
        unidades_por_bulto: '',
        stock_bultos: '',
        stock_unidades: '',
        stock_kilos: '',
        es_refrigerado: false,
        fecha_vencimiento: '',
        proveedores: [], // Array para múltiples proveedores
        codigo_barras: [{ codigo: '' }], // Array de objetos para manejar múltiples códigos de barras
        precios: {
            precio_compra: 0,
            precio: 0,
            precio_mayorista_a: 0,
            precio_mayorista_b: 0,
            precio_mayorista_c: 0,
        },
    });

    const [proveedores, setProveedores] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (productId) {
                    const productResponse = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin/${productId}`);
                    const producto = productResponse.data.producto;
                    console.log(producto);

                    const precioActivo = producto.precios.find(p => p.activo) || producto.precios[0] || {
                        precio_compra: 0,
                        precio: 0,
                        precio_mayorista_a: 0,
                        precio_mayorista_b: 0,
                        precio_mayorista_c: 0,
                    };

                    const proveedorIds = producto.proveedores ? producto.proveedores.map((p) => parseInt(p.id, 10)) : [];

                    setProduct({
                        ...producto,
                        proveedores: proveedorIds,
                        codigo_barras: producto.codigo_barras || [''],
                        precios: {
                            precio_compra: parseFloat(precioActivo.precio_compra) || 0,
                            precio: parseFloat(precioActivo.precio) || 0,
                            precio_mayorista_a: parseFloat(precioActivo.precio_mayorista_a) || 0,
                            precio_mayorista_b: parseFloat(precioActivo.precio_mayorista_b) || 0,
                            precio_mayorista_c: parseFloat(precioActivo.precio_mayorista_c) || 0,
                        },
                    });
                }

                const proveedoresResponse = await axios.get(`${process.env.REACT_APP_API_URL}/proveedores`);
                setProveedores(proveedoresResponse.data.proveedores);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        fetchData();
    }, [productId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            // Para el checkbox de es_refrigerado
            if (name === 'es_refrigerado') {
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    es_refrigerado: checked,
                }));
            } else if (name === 'proveedores') {
                // Para el checkbox de proveedores
                const proveedorId = parseInt(value, 10);
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    proveedores: checked
                        ? [...prevProduct.proveedores, proveedorId]
                        : prevProduct.proveedores.filter((id) => id !== proveedorId),
                }));
            }
        } else {
            // Para otros tipos de input
            setProduct((prevProduct) => ({
                ...prevProduct,
                [name]: type === 'number' ? (value ? parseFloat(value) : '') : value,
            }));
        }
    };

    const handleAddCodigoBarras = () => {
        setProduct((prevProduct) => ({
            ...prevProduct,
            codigo_barras: [...prevProduct.codigo_barras, { codigo: '' }],
        }));
    };

    const handleRemoveCodigoBarras = (index) => {
        setProduct((prevProduct) => ({
            ...prevProduct,
            codigo_barras: prevProduct.codigo_barras.filter((_, i) => i !== index),
        }));
    };

    const handleChangeCodigoBarras = (index, value) => {
        const updatedCodigos = [...product.codigo_barras];
        updatedCodigos[index].codigo = value;
        setProduct({
            ...product,
            codigo_barras: updatedCodigos,
        });
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            precios: {
                ...prevProduct.precios,
                [name]: value ? parseFloat(value) : 0,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const apiUrl = productId
                ? `${process.env.REACT_APP_API_URL}/productos/admin/${productId}`
                : '/api/productos';
            const method = productId ? 'put' : 'post';

            await axios[method](apiUrl, product);
            onSave();
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    const handleOpenCreateProv = () => setOpen(true);
    const onClose = () => setOpen(false);

    const onCreateProveedor = async () => {
        try {
            const proveedoresResponse = await axios.get(`${process.env.REACT_APP_API_URL}/proveedores`);
            setProveedores(proveedoresResponse.data.proveedores);
        } catch (error) {
            console.error('Error al obtener proveedores:', error);
        }
    };

    

    return (
        <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto ">
            <h2 className="text-2xl font-bold text-center mb-6">
                {productId ? 'Actualizar Producto' : 'Agregar Producto'}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Nombre del Producto */}
                <div>
                    <label htmlFor="nombre_producto" className="block font-medium text-gray-700">Nombre del Producto:</label>
                    <input
                        type="text"
                        id="nombre_producto"
                        name="nombre_producto"
                        value={product.nombre_producto}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/* Tipo de Producto */}
                <div>
                    <label htmlFor="tipo_producto" className="block font-medium text-gray-700">Tipo de Producto:</label>
                    <select
                        id="tipo_producto"
                        name="tipo_producto"
                        value={product.tipo_producto}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <option value="unidad">Unidad</option>
                        <option value="bulto">Bulto</option>
                        <option value="kilo">Kilo</option>
                    </select>
                </div>

                {/* Unidades por Bulto */}
                {product.tipo_producto === 'bulto' && (
                    <div>
                        <label htmlFor="unidades_por_bulto" className="block font-medium text-gray-700">Unidades por Bulto:</label>
                        <input
                            type="number"
                            id="unidades_por_bulto"
                            name="unidades_por_bulto"
                            value={product.unidades_por_bulto || ''}
                            onChange={handleChange}
                            min="0"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                )}

                {/* Stock en Bultos */}
                {product.tipo_producto === 'bulto' && (
                    <div>
                        <label htmlFor="stock_bultos" className="block font-medium text-gray-700">Stock de Bultos:</label>
                        <input
                            type="number"
                            id="stock_bultos"
                            name="stock_bultos"
                            value={product.stock_bultos || ''}
                            onChange={handleChange}
                            min="0"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                )}

                {/* Stock en Unidades */}
                {product.tipo_producto === 'unidad' && (
                    <div>
                        <label htmlFor="stock_unidades" className="block font-medium text-gray-700">Stock de Unidades:</label>
                        <input
                            type="number"
                            id="stock_unidades"
                            name="stock_unidades"
                            value={product.stock_unidades || ''}
                            onChange={handleChange}
                            min="0"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                )}

                {/* Stock en Kilos */}
                {product.tipo_producto === 'kilo' && (
                    <div>
                        <label htmlFor="stock_kilos" className="block font-medium text-gray-700">Stock de Kilos:</label>
                        <input
                            type="number"
                            id="stock_kilos"
                            name="stock_kilos"
                            value={product.stock_kilos || ''}
                            onChange={handleChange}
                            min="0"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                )}



                {/* Es Refrigerado */}
                <div className="flex items-center space-x-2">
                    <label htmlFor="es_refrigerado" className="font-medium text-gray-700">¿Es Refrigerado?</label>
                    <input
                        type="checkbox"
                        id="es_refrigerado"
                        name="es_refrigerado"
                        checked={product.es_refrigerado}
                        onChange={handleChange}
                        className="h-5 w-5 text-indigo-500"
                    />
                </div>

                {/* Fecha de Vencimiento */}
                <div>
                    <label htmlFor="fecha_vencimiento" className="block font-medium text-gray-700">Fecha de Vencimiento:</label>
                    <input
                        type="date"
                        id="fecha_vencimiento"
                        name="fecha_vencimiento"
                        value={product.fecha_vencimiento || ''}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/* Códigos de Barras */}
                <div>
                    <label className="block font-medium text-gray-700">Códigos de Barras:</label>
                    {product.codigo_barras.map((codigoObj, index) => (
                        <div key={index} className="flex items-center space-x-4 mb-2">
                            <input
                                type="text"
                                value={codigoObj.codigo}
                                onChange={(e) => handleChangeCodigoBarras(index, e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveCodigoBarras(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                <FaTrash />
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddCodigoBarras}
                        className="flex items-center text-indigo-600 hover:text-indigo-800 mt-2"
                    >
                        <FaPlus className="mr-1" />
                        Agregar Código de Barra
                    </button>
                </div>

                {/* Proveedores */}
                <div className="col-span-2">
                    <label className="block font-medium text-gray-700">Proveedores:</label>
                    {proveedores.map((proveedor) => (
                        <div key={proveedor.id} className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                value={proveedor.id}
                                checked={product.proveedores.includes(proveedor.id)}
                                onChange={handleChange}
                                name="proveedores"
                                className="h-5 w-5 text-indigo-500"
                            />
                            <span>{proveedor.nombre}</span>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleOpenCreateProv}
                        className="flex items-center text-indigo-600 hover:text-indigo-800 mt-2"
                    >
                        <FaPlus className="mr-1" />
                        Agregar Proveedor
                    </button>
                    {open && (
                        <CompCreateProv onClose={onClose} onCreateProveedor={onCreateProveedor} />
                    )}
                </div>

                {/* Precios */}
                <div className="col-span-2 space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {['precio_compra', 'precio unitario', 'precio_mayorista_a', 'precio_mayorista_b', 'precio_mayorista_c'].map((field) => (
                            <div key={field}>
                                <label htmlFor={field} className="block font-medium text-gray-700">{field.replace('_', ' ').toUpperCase()}:</label>
                                <input
                                    type="number"
                                    id={field}
                                    name={field}
                                    value={product.precios[field] || 0}
                                    onChange={handlePriceChange}
                                    min="0"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Botones de acción */}
            <div className="flex justify-end space-x-4 mt-6">
                <button
                    type="button"
                    onClick={() => handleCloseEdit()}
                    className="flex items-center bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-6 rounded-md"
                >
                    <FaTimes className="mr-2" />
                    Cancelar
                </button>
                <button
                    type="submit"
                    className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-6 rounded-md"
                >
                    <FaSave className="mr-2" />
                    {productId ? 'Actualizar Producto' : 'Agregar Producto'}
                </button>
            </div>
        </form>
    );
}

export default CompEditProduct;
