import React, { useState, useEffect, useRef } from 'react';
import CompEditProduct from './EditProducto';

const CompInventario = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null); // Almacena el ID del producto seleccionado para editar

  // Referencia al contenedor del componente de edición
  const editProductRef = useRef(null);

  // Obtener productos de la API al cargar el componente
  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/productos/admin`);
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        const data = await response.json();
        setInventory(data.productos);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  // Manejar la selección de producto para edición
  const handleEditProduct = (productId) => {
    setSelectedProductId(productId); // Establece el producto a editar
    // Desplazarse a la referencia del componente de edición
    setTimeout(() => {
      editProductRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  };

  // Función para recargar el inventario después de guardar cambios
  const reloadInventory = async () => {
    setLoading(true);
    setSelectedProductId(null); // Cierra el formulario de edición
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/productos/admin`);
      const data = await response.json();
      setInventory(data.productos);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Función para cerrar la edición
  const handleCloseEdit = () => {
    setSelectedProductId(null); // Cierra el formulario de edición
  };

  return (
    <div className="flex items-start justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg w-full max-w-6xl flex flex-col lg:flex-row">

        {/* Sección de Inventario */}
        <div className={`w-full ${selectedProductId ? 'lg:w-2/5' : 'w-full'} p-8`}>
          <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Inventario</h1>
          <h2 className="text-xl font-semibold mb-4">Inventario Actual</h2>

          {loading && <p className="text-center text-gray-500">Cargando inventario...</p>}
          {error && <p className="text-center text-red-500">Error: {error}</p>}

          {/* Lista de productos obtenidos */}
          {!loading && !error && inventory.length > 0 ? (
            <ul className="space-y-4">
              {inventory.map((product) => (
                <li
                  key={product.id}
                  className="border p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <h3 className="text-lg font-bold">{product.nombre_producto}</h3>
                  <p className="text-sm text-gray-600">Stock en palets: {product.stock_palets}</p>
                  <p className="text-sm text-gray-600">Stock en unidades: {product.stock_unidades}</p>
                  <p className="text-sm text-gray-600">Stock en kilos: {product.stock_kilos}</p>
                  <button
                    onClick={() => handleEditProduct(product.id)}
                    className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            !loading && <p className="text-center text-gray-500">No hay productos en el inventario.</p>
          )}
        </div>

        {/* Sidebar de Edición */}
        {selectedProductId && (
          <div
            ref={editProductRef}
            className={`
              bg-gray-50 border-l border-gray-300 px-4 top-4 h-full max-h-screen overflow-y-auto 
              ${selectedProductId ? "flex-shrink-0" : ""} 
              ${selectedProductId ? "lg:w-3/5" : "lg:w-full"} 
              ${selectedProductId ? "absolute inset-0 md:sticky" : ""}
              ${selectedProductId ? "fixed md:static md:top-0" : ""}
            `}
          >
            <CompEditProduct
              productId={selectedProductId}
              onSave={reloadInventory}
              handleCloseEdit={handleCloseEdit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompInventario;
