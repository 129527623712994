import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(false); // Estado para controlar la visibilidad de la sidebar
    const [isSalesOpen, setSalesOpen] = useState(false); // Estado para controlar la visibilidad de las subopciones de ventas

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev); // Alternar la visibilidad de la sidebar
    };

    const closeSidebar = () => {
        setSidebarOpen(false); // Cerrar la sidebar
        setSalesOpen(false); // También cerrar las subopciones de ventas
    };

    const toggleSales = () => {
        setSalesOpen((prev) => !prev); // Alternar la visibilidad de las subopciones de ventas
    };

    return (
        <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, closeSidebar, isSalesOpen, toggleSales }}>
            {children}
        </SidebarContext.Provider>
    );
};
