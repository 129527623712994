import React, { useState } from 'react';
import axios from 'axios';

const RegistroMovimiento = ({ cajaId, manejarMovimiento }) => {
  const [monto, setMonto] = useState('');
  const [concepto, setConcepto] = useState('');
  const [tipoMovimiento, setTipoMovimiento] = useState('ingreso');
  const [formaPago, setFormaPago] = useState('efectivo');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const registrarMovimiento = async () => {
    if (!monto || isNaN(monto) || !concepto) {
      setError('Ingrese todos los campos correctamente.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/caja/movimiento`, {
        caja_id: cajaId,
        tipo_movimiento: tipoMovimiento,
        monto: parseFloat(monto),
        concepto: concepto,
        forma_pago: formaPago,
      });
      setSuccess('Movimiento registrado con éxito.');
      setError('');

      // Actualizar el saldo después de registrar el movimiento
      manejarMovimiento({ tipoMovimiento, monto: parseFloat(monto) });

      // Reiniciar los campos después de registrar el movimiento
      setMonto('');
      setConcepto('');
    } catch (error) {
      setError('Error al registrar el movimiento.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Registro de Movimiento</h2>

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Concepto
        </label>
        <input
          type="text"
          value={concepto}
          onChange={(e) => setConcepto(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Ingrese el concepto"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Monto
        </label>
        <input
          type="number"
          value={monto}
          onChange={(e) => setMonto(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Ingrese el monto"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Tipo de Movimiento
        </label>
        <select
          value={tipoMovimiento}
          onChange={(e) => setTipoMovimiento(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="ingreso">Ingreso</option>
          <option value="egreso">Egreso</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Forma de Pago
        </label>
        <select
          value={formaPago}
          onChange={(e) => setFormaPago(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="efectivo">Efectivo</option>
          <option value="tarjeta">Tarjeta</option>
          <option value="transferencia">Transferencia</option>
          <option value="otros">Otros</option>
        </select>
      </div>

      <button
        onClick={registrarMovimiento}
        className="bg-green-500 text-white p-2 rounded-md w-full hover:bg-green-600"
      >
        Registrar Movimiento
      </button>
    </div>
  );
};

export default RegistroMovimiento;
