import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CompCarrito = () => {
  const [cart, setCart] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [useWholesale, setUseWholesale] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`);
        setProductsData(response.data.productos);
        setFilteredProducts(response.data.productos);
      } catch (error) {
        console.error('Error al cargar productos:', error);
        setError('No se pudo cargar la lista de productos.');
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    setFilteredProducts(
      productsData.filter((product) =>
        product.nombre_producto.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, productsData]);

  const getPrice = (product) => {
    if (useWholesale) {
      return (
        product.precios[0].precio_mayorista_c ||
        product.precios[0].precio_mayorista_b ||
        product.precios[0].precio_mayorista_a ||
        product.precios[0].precio
      );
    }
    return product.precios[0].precio;
  };

  const isDiscounted = (product) => product.tieneDescuento;

  const getDiscountedPrice = (product) => {
    if (isDiscounted(product)) {
      return parseFloat(product.precioFinal.toFixed(2));
    }
    return getPrice(product);
  };

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((p) => p.id === product.id);
      if (existingProduct) {
        return prevCart.map((p) =>
          p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((product) => product.id !== productId));
  };

  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) =>
        product.id === productId ? { ...product, quantity: product.quantity + 1 } : product
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) =>
        product.id === productId && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  const calculateTotal = () => {
    const total = cart.reduce((acc, product) => acc + getDiscountedPrice(product) * product.quantity, 0);
    return parseFloat(total.toFixed(2));
  };

  const handleCheckout = async () => {
    if (!paymentMethod) {
      alert('Por favor, selecciona un método de pago.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const payload = {
        productos: cart.map((item) => ({
          id: item.id,
          cantidad: item.quantity,
          precio: getDiscountedPrice(item),
          tipo_producto: item.tipo_producto,
          unidades_por_bulto: item.unidades_por_bulto,
          bultos_por_palet: item.bultos_por_palet,
          stock_palets: item.stock_palets,
          stock_bultos: item.stock_bultos,
          stock_unidades: item.stock_unidades,
          stock_kilos: item.stock_kilos,
        })),
        total: calculateTotal(),
        mayorista: useWholesale,
        detalles_pago: paymentMethod,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Error al realizar la venta');
      }

      const result = await response.json();
      setCart([]);
      alert('Venta realizada con éxito');
    } catch (error) {
      setError('Hubo un problema al realizar la compra.');
    } finally {
      setLoading(false);
    }
  };

  const isNearExpiry = (product) => {
    if (!product.fecha_vencimiento) return false;
    const hoy = new Date();
    const diasHastaVencimiento = Math.floor((new Date(product.fecha_vencimiento) - hoy) / (1000 * 60 * 60 * 24));
    return diasHastaVencimiento <= 7;
  };

  return (

    <div className="container mx-auto p-4 max-w-7xl">
      <h2 className="text-3xl font-bold text-center mb-6">Presupuesto</h2>

      {/* Buscador de productos */}
      <div className="mb-6 flex justify-center">
        <input
          type="text"
          placeholder="Buscar producto..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full md:w-1/2 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
        />
      </div>

      {/* Sección de productos filtrados */}
      <div className="overflow-x-auto mb-6">
        <div className="flex space-x-6 px-2 md:px-4">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className={`w-48 sm:w-56 bg-white p-4 rounded-lg shadow-lg flex-none ${isDiscounted(product) ? 'border-2 border-red-500' : ''
                } ${isNearExpiry(product) ? 'bg-yellow-100' : ''}`}
            >
              <div className="text-center">
                <h3 className="text-lg font-bold text-gray-800">{product.nombre_producto}</h3>
                <p className={`text-xl font-semibold mt-2 ${isDiscounted(product) ? 'text-red-600 line-through' : 'text-green-700'}`}>
                  ${getPrice(product)}
                </p>
                {isDiscounted(product) && (
                  <p className="text-xl font-semibold text-green-700 mt-2">${getDiscountedPrice(product)}</p>
                )}
                {isNearExpiry(product) && (
                  <p className="text-sm font-bold text-yellow-700 mt-1">¡Cerca de vencimiento!</p>
                )}
              </div>
              <button
                onClick={() => addToCart(product)}
                className="mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
              >
                Agregar al carrito
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Producto recientemente agregado */}
      {cart.length > 0 && (
        <div className="text-center mb-6 mt-4 sticky bottom-24 z-10 bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold text-gray-800">Producto Agregado Recientemente</h3>
          <div className="mt-4">
            <div className="text-xl font-semibold text-gray-800">{cart[cart.length - 1].nombre_producto}</div>
            <div className="text-3xl font-bold text-green-700">${getDiscountedPrice(cart[cart.length - 1])}</div>
          </div>
        </div>
      )}

      {/* Carrito en formato de presupuesto */}
      {cart.length > 0 ? (
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left font-semibold text-gray-700">Producto</th>
              <th className="p-3 text-left font-semibold text-gray-700">Precio Unitario</th>
              <th className="p-3 text-left font-semibold text-gray-700">Cantidad</th>
              <th className="p-3 text-left font-semibold text-gray-700">Subtotal</th>
              <th className="p-3 text-left font-semibold text-gray-700">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item) => (
              <tr key={item.id}>
                <td className="p-3 text-gray-800">{item.nombre_producto}</td>
                <td className="p-3 text-gray-800">${getDiscountedPrice(item)}</td>
                <td className="p-3 text-gray-800">{item.quantity}</td>
                <td className="p-3 text-gray-800">${(getDiscountedPrice(item) * item.quantity).toFixed(2)}</td>
                <td className="p-3 flex gap-2 justify-center">
                  <button
                    onClick={() => increaseQuantity(item.id)}
                    className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                  >
                    +
                  </button>
                  <button
                    onClick={() => decreaseQuantity(item.id)}
                    className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                  >
                    -
                  </button>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center text-gray-700 mt-4">No hay productos en el carrito.</p>
      )}

      {/* Total siempre visible y aumentado */}
      <div className="sticky bottom-16 z-20 bg-white p-4 rounded-lg shadow-md flex justify-between items-center text-3xl font-semibold text-gray-800">
        <span>Total:</span>
        <span>${calculateTotal()}</span>
      </div>

      {/* Métodos de pago */}
      <div className="mt-6">
        <label className="block text-gray-700">Método de pago</label>
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
        >
          <option value="">Seleccione un método de pago</option>
          <option value="efectivo">Efectivo</option>
          <option value="tarjeta">Tarjeta de crédito</option>
        </select>
      </div>

      {/* Botón de confirmación */}
      <div className="text-center mt-6">
        <button
          onClick={handleCheckout}
          className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600"
        >
          Confirmar Compra
        </button>
      </div>
    </div>

  );
};

export default CompCarrito;
