import React, { useState, useEffect } from 'react';
import AperturaCaja from './aperturaCaja';
import RegistroMovimiento from './registroMovimiento';
import axios from 'axios';
import CierreCaja from './cierreCaja.js';

const CajaManager = () => {
  const [cajaAbierta, setCajaAbierta] = useState(null);
  const [saldoFinalCalculado, setSaldoFinalCalculado] = useState(0); // Asegúrate de que el estado inicial es 0

  useEffect(() => {
    // Cargar la caja abierta desde localStorage al montar el componente
    const cajaIdGuardada = localStorage.getItem('cajaAbiertaId');
    if (cajaIdGuardada) {
      // Aquí puedes hacer una llamada a tu API para obtener la caja por ID
      const obtenerCajaAbierta = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/${cajaIdGuardada}`);
          if (response.data) {
            setCajaAbierta(response.data);

            setSaldoFinalCalculado(response.data.saldo_inicial || 0); // Asegúrate de que no sea null
          }
        } catch (error) {
          console.error('Error al obtener la caja:', error);
        }
      };
      obtenerCajaAbierta();
    }
  }, []);

  useEffect(() => {
    // Guardar la ID de la caja abierta en localStorage cuando se abre la caja
    if (cajaAbierta) {

      localStorage.setItem('cajaAbiertaId', cajaAbierta.id);
    }
  }, [cajaAbierta]);

  const manejarMovimiento = (nuevoMovimiento) => {
    console.log(nuevoMovimiento);
    const nuevoSaldo = saldoFinalCalculado + (nuevoMovimiento.tipoMovimiento === 'ingreso' ? nuevoMovimiento.monto : -nuevoMovimiento.monto);
    setSaldoFinalCalculado(nuevoSaldo);
  };

  const manejarCerrarCaja = () => {
    // Eliminar la ID de la caja abierta de localStorage al cerrar la caja
    localStorage.removeItem('cajaAbiertaId');
    setCajaAbierta(null); // Restablecer el estado de cajaAbierta
    setSaldoFinalCalculado(0); // Restablecer el saldo final a 0
  };

  const manejarCajaAbierta = (cajaData) => {
    setCajaAbierta(cajaData);
    setSaldoFinalCalculado(cajaData.saldo_inicial); // Establecer el saldo al abrir la caja
  };


  return (
    <div className="container mx-auto p-6">
      {!cajaAbierta ? (
        <AperturaCaja onCajaAbierta={manejarCajaAbierta} />
      ) : (
        <>
          <RegistroMovimiento cajaId={cajaAbierta.id} manejarMovimiento={manejarMovimiento} />
          <CierreCaja cajaId={cajaAbierta.id} saldoFinalCalculado={saldoFinalCalculado} onCerrarCaja={manejarCerrarCaja} />
        </>
      )}
    </div>
  );
};

export default CajaManager;
