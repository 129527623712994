import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import CompCreateProv from './createProv';

const LabelInput = React.memo(({ label, ...props }) => (
  <label className="block mb-4">
    <span className="text-lg font-medium text-gray-700">{label}</span>
    <input
      {...props}
      className="mt-2 w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
    />
  </label>
));
function CompCrearProducto() {
  const [proveedores, setProveedores] = useState([]);
  const [showCreateProvModal, setShowCreateProvModal] = useState(false);

  const [formData, setFormData] = useState({
    nombre_producto: '',
    tipo_producto: 'unidad', // Tipo de producto: unidad, bulto, palet
    unidades_por_bulto: '',
    bultos_por_palet: '',
    precio: '',
    precio_compra: '',
    precio_mayorista_a: '',
    precio_mayorista_b: '',
    precio_mayorista_c: '',
    codigo_barras: [''],
    fecha_vencimiento: '',
    stock_palets: '',
    stock_bultos: '',
    stock_unidades: '',
    stock_kilos: '',
    fecha_vigencia: new Date().toISOString().split('T')[0], // Fecha de vigencia por defecto a hoy
    proveedores_ids: [],
    es_refrigerado: false,
  });

  useEffect(() => {
    const fetchProvs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/proveedores`);
        setProveedores(response.data.data);
      } catch (error) {
        console.error('Error al obtener los proveedores:', error);
      }
    };
    fetchProvs();
  }, [showCreateProvModal]);

  useEffect(() => {
    console.log(formData.proveedores_ids);

  }, [formData.proveedores_ids]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCodigoReferenciaChange = (index, value) => {
    const updatedCodigos = [...formData.codigo_barras];
    updatedCodigos[index] = value;
    setFormData((prev) => ({ ...prev, codigo_barras: updatedCodigos }));
  };

  const addCodigoReferencia = () => {
    setFormData((prev) => ({
      ...prev,
      codigo_barras: [...prev.codigo_barras, ''],
    }));
  };

  const handleProveedorChange = (e) => {
    const selected = Array.from(e.target.selectedOptions, (opt) => opt.value);
    setFormData((prev) => ({ ...prev, proveedores_ids: selected }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/productos`, formData);
      setFormData({
        nombre_producto: '',
        tipo_producto: 'unidad',
        unidades_por_bulto: '',
        bultos_por_palet: '',
        precio: '',
        precio_compra: '',
        precio_mayorista_a: '',
        precio_mayorista_b: '',
        precio_mayorista_c: '',
        codigo_barras: [''],
        fecha_vencimiento: '',
        stock_palets: '',
        stock_bultos: '',
        stock_unidades: '',
        stock_kilos: '',
        proveedores_ids: [],
        es_refrigerado: false,
      });
    } catch (error) {
      console.error('Error al crear la compra:', error);
    }
  };


  return (
    <motion.form onSubmit={handleSubmit} className="flex flex-col w-full max-w-2xl items-center justify-center m-auto p-6 bg-white rounded-lg shadow-md">
      <div className="w-full">
        <label className="block mb-4">

          <LabelInput
            label="Nombre:"
            type="text"
            name="nombre_producto"
            value={formData.nombre_producto}
            onChange={handleChange}
          />

          <span className="text-lg font-medium text-gray-700">Tipo de Producto:</span>
          <select
            name="tipo_producto"
            value={formData.tipo_producto}
            onChange={handleChange}
            className="mt-2 w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option value="unidad">Unidad</option>
            <option value="bulto">Bulto</option>
            <option value="kilo">Kilos</option>
          </select>
        </label>

        {formData.tipo_producto === 'unidad' && (
          <LabelInput
            label="Cantidad de Unidades:"
            type="number"
            name="stock_unidades"
            value={formData.stock_unidades}
            onChange={handleChange}
          />
        )}

        {formData.tipo_producto === 'bulto' && (
          <>
            <LabelInput
              label="Cantidad de Bultos:"
              type="number"
              name="stock_bultos"
              value={formData.stock_bultos}
              onChange={handleChange}
            />
            <LabelInput
              label="Unidades por Bulto:"
              type="number"
              name="unidades_por_bulto"
              value={formData.unidades_por_bulto}
              onChange={handleChange}
            />
          </>
        )}

        {formData.tipo_producto === 'palet' && (
          <>
            <LabelInput
              label="Cantidad de Palets:"
              type="number"
              name="stock_palets"
              value={formData.stock_palets}
              onChange={handleChange}
            />
            <LabelInput
              label="Bultos por Palet:"
              type="number"
              name="bultos_por_palet"
              value={formData.bultos_por_palet}
              onChange={handleChange}
            />
            <LabelInput
              label="Unidades por Bulto:"
              type="number"
              name="unidades_por_bulto"
              value={formData.unidades_por_bulto}
              onChange={handleChange}
            />
          </>
        )}

        {formData.tipo_producto === 'kilo' && (
          <LabelInput
            label="Cantidad de Kilos:"
            type="number"
            name="stock_kilos"
            value={formData.stock_kilos}
            onChange={handleChange}
          />
        )}

        <label className="block mb-4">
          <span className="text-lg font-medium text-gray-700">Código de Referencia:</span>
          {formData.codigo_barras.map((codigo, index) => (
            <div key={index} className="flex gap-2 mt-2">
              <input
                type="text"
                value={codigo}
                onChange={(e) => handleCodigoReferenciaChange(index, e.target.value)}
                className="flex-1 px-4 py-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addCodigoReferencia}
            className="mt-2 inline-flex items-center bg-blue-500 text-white px-3 py-2 rounded-md transition duration-200 hover:bg-blue-600"
          >
            <FaPlus className="mr-2" /> Agregar Código
          </button>
        </label>

        <LabelInput
          label="Fecha de Vencimiento:"
          type="date"
          name="fecha_vencimiento"
          value={formData.fecha_vencimiento}
          onChange={handleChange}
        />

        <LabelInput
          label="Fecha de Vigencia:"
          type="date"
          name="fecha_vigencia"
          value={formData.fecha_vigencia}
          onChange={handleChange}
          readOnly
        />

        <label className="block mb-4">
          <span className="text-lg font-medium text-gray-700">Proveedor:</span>
          <select
            multiple
            value={formData.proveedores_ids}
            onChange={handleProveedorChange}
            className="mt-2 w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            {proveedores.map((prov) => (
              <option key={prov.id} value={prov.id}>
                {prov.nombre}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => setShowCreateProvModal(true)}
            className="mt-2 inline-flex items-center bg-blue-500 text-white px-3 py-2 rounded-md transition duration-200 hover:bg-blue-600"
          >
            <FaPlus className="mr-2" /> Agregar Proveedor
          </button>
        </label>

        {/* Grid para precios */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <LabelInput
            label="Precio:"
            type="number"
            name="precio"
            value={formData.precio}
            onChange={handleChange}
            className="border-blue-300" // Color para precio de venta
          />

          <LabelInput
            label="Precio de Compra:"
            type="number"
            name="precio_compra"
            value={formData.precio_compra}
            onChange={handleChange}
            className="border-green-300" // Color para precio de compra
          />

          <LabelInput
            label="Precio Mayorista A:"
            type="number"
            name="precio_mayorista_a"
            value={formData.precio_mayorista_a}
            onChange={handleChange}
          />

          <LabelInput
            label="Precio Mayorista B:"
            type="number"
            name="precio_mayorista_b"
            value={formData.precio_mayorista_b}
            onChange={handleChange}
          />

          <LabelInput
            label="Precio Mayorista C:"
            type="number"
            name="precio_mayorista_c"
            value={formData.precio_mayorista_c}
            onChange={handleChange}
          />
        </div>

        <label className="flex items-center mb-4">
          <input
            type="checkbox"
            name="es_refrigerado"
            checked={formData.es_refrigerado}
            onChange={handleChange}
            className="mr-2"
          />
          <span className="text-lg font-medium text-gray-700">¿Es Refrigerado?</span>
        </label>
      </div>

      <div className="flex justify-end mt-6 w-full">
        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-2 rounded-md transition duration-200 hover:bg-blue-600"
        >
          Guardar Producto
        </button>
      </div>
    </motion.form>

  );
}

export default CompCrearProducto;
