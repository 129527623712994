import React from 'react';
import { Link } from 'react-router-dom';
import { FaCashRegister, FaBoxes, FaCartPlus, FaStore, FaTags, FaListAlt, FaDollarSign, FaClipboardList } from 'react-icons/fa';

const CompShowInicio = () => {
    //FIXME: por algun motivo no se muestra el producto como un modelo relacionado, por lo que tengo que revisar el backend


    return (
        <main className="flex-grow flex items-center justify-center mt-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-4xl p-6">
          
          {/* Caja */}
          <Link
            to="/caja"
            className="bg-gradient-to-r from-indigo-500 to-blue-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-blue-600 hover:to-indigo-600 transition transform hover:scale-105"
          >
            <FaCashRegister className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Caja</h2>
            <p className="text-sm">Accede al sistema de caja</p>
          </Link>
          
          {/* Ver Todas las Cajas */}
          <Link
            to="/caja/todas"
            className="bg-gradient-to-r from-green-500 to-teal-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-teal-600 hover:to-green-600 transition transform hover:scale-105"
          >
            <FaClipboardList className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Ver Todas las Cajas</h2>
            <p className="text-sm">Consulta todas las cajas</p>
          </Link>
  
          {/* Venta */}
          <Link
            to="/vender"
            className="bg-gradient-to-r from-pink-500 to-red-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-red-600 hover:to-pink-600 transition transform hover:scale-105"
          >
            <FaDollarSign className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Venta</h2>
            <p className="text-sm">Accede al sistema de ventas</p>
          </Link>
  
          {/* Crear Producto */}
          <Link
            to="/crearproducto"
            className="bg-gradient-to-r from-yellow-500 to-orange-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-orange-600 hover:to-yellow-600 transition transform hover:scale-105"
          >
            <FaBoxes className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Crear Producto</h2>
          </Link>
  
          {/* Comprar */}
          <Link
            to="/comprar"
            className="bg-gradient-to-r from-purple-500 to-fuchsia-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-fuchsia-600 hover:to-purple-600 transition transform hover:scale-105"
          >
            <FaCartPlus className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Comprar</h2>
            <p className="text-sm">Accede al sistema de compras</p>
          </Link>
  
          {/* Inventario */}
          <Link
            to="/inventario"
            className="bg-gradient-to-r from-blue-400 to-cyan-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-cyan-600 hover:to-blue-600 transition transform hover:scale-105"
          >
            <FaStore className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Inventario</h2>
            <p className="text-sm">Gestiona el inventario</p>
          </Link>
  
          {/* Crear Descuentos */}
          <Link
            to="/crearDescuento"
            className="bg-gradient-to-r from-red-400 to-pink-400 shadow-lg rounded-lg p-6 text-center text-white hover:from-pink-500 hover:to-red-500 transition transform hover:scale-105"
          >
            <FaTags className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Crear Descuentos</h2>
          </Link>
  
          {/* Ver Ventas Realizadas */}
          <Link
            to="/ventas"
            className="bg-gradient-to-r from-green-400 to-teal-400 shadow-lg rounded-lg p-6 text-center text-white hover:from-teal-500 hover:to-green-500 transition transform hover:scale-105"
          >
            <FaListAlt className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Ver Ventas Realizadas</h2>
          </Link>
  
        </div>
      </main>

    );
};

export default CompShowInicio;
