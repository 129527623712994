import React, { useState } from 'react';
import axios from 'axios';

const CierreCaja = ({ cajaId, saldoFinalCalculado, onCerrarCaja }) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const cerrarCaja = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/caja/cerrar/${cajaId}`, {
        saldo_final: saldoFinalCalculado, // Guarda el saldo final
      });
      setSuccess('Caja cerrada con éxito.');
      setError('');
      onCerrarCaja(); // Llama a la función para eliminar la ID de localStorage
    } catch (error) {
      setError('Error al cerrar la caja.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Cierre de Caja</h2>

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}

      <p className="mb-4">Saldo Final Calculado: ${saldoFinalCalculado.toFixed(2)}</p> {/* Asegúrate de mostrarlo */}

      <button
        onClick={cerrarCaja}
        className="bg-red-500 text-white p-2 rounded-md w-full hover:bg-red-600"
      >
        Cerrar Caja
      </button>
    </div>
  );
};

// Exportar el componente correctamente
export default CierreCaja;
