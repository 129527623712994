import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useSidebar } from './sidebarContext';
import { FaTimes } from 'react-icons/fa'; // Importa el ícono de cerrar

const CompSidebar = () => {
    const { isSalesOpen, toggleSales, closeSidebar, isSidebarOpen } = useSidebar();

    return (
        <aside className={`bg-gray-200 text-black h-full p-4 w-64 shadow-lg fixed transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} z-50`}>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Mi Aplicación</h1>
                <button onClick={closeSidebar} className="text-black hover:text-red-500 transition">
                    <FaTimes size={24} />
                </button>
            </div>
            <nav>
                <ul>
                    <li className="mb-2">
                        <Link to="/" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Inicio
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/caja" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Caja
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/caja/todas" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Ver Todas las Cajas
                        </Link>
                    </li>
                    <li className="mb-2">
                        <button onClick={toggleSales} className="w-full text-left py-2 px-4 hover:bg-white rounded transition">
                            Ventas
                        </button>
                        {isSalesOpen && (
                            <motion.ul
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.3 }}
                                className="ml-4"
                            >
                                <li className="mb-2">
                                    <Link to="/vender" onClick={closeSidebar} className="block py-2 px-4 hover:bg-gray-600 rounded transition">
                                        Realizar Ventas
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/ventas" onClick={closeSidebar} className="block py-2 px-4 hover:bg-gray-600 rounded transition">
                                        Ver Ventas Realizadas
                                    </Link>
                                </li>
                            </motion.ul>
                        )}
                    </li>
                    <li className="mb-2">
                        <Link to="/comprar" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Comprar
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/inventario" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Inventario
                        </Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/crearDescuento" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Crear Descuentos
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default CompSidebar;
