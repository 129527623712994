// src/components/Sales.js
import React, { useState } from 'react';

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [currentSale, setCurrentSale] = useState([]);
  const [product, setProduct] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState('');

  const handleAddProduct = (e) => {
    e.preventDefault();
    const newProduct = { product, quantity, price: parseFloat(price) };
    setCurrentSale([...currentSale, newProduct]);
    setProduct('');
    setQuantity(1);
    setPrice('');
  };

  const handleFinalizeSale = () => {
    setSales([...sales, currentSale]);
    setCurrentSale([]);
  };

  const calculateSubtotal = () => {
    return currentSale.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl">
        <h1 className="text-2xl font-bold mb-6 text-center">Sistema de Ventas</h1>
        <form onSubmit={handleAddProduct} className="mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product">
              Producto
            </label>
            <input
              type="text"
              id="product"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
              Cantidad
            </label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
              min="1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
              Precio
            </label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              step="0.01"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Agregar Producto
            </button>
          </div>
        </form>
        <div>
          <h2 className="text-xl font-semibold mb-4">Productos en la Venta Actual</h2>
          <ul>
            {currentSale.map((item, index) => (
              <li key={index} className="mb-2">
                <div className="bg-gray-200 p-4 rounded-lg">
                  <p><strong>Producto:</strong> {item.product}</p>
                  <p><strong>Cantidad:</strong> {item.quantity}</p>
                  <p><strong>Precio:</strong> ${item.price.toFixed(2)}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-4 p-4 bg-gray-200 rounded-lg text-center">
            <h3 className="text-xl font-bold">Subtotal: ${calculateSubtotal().toFixed(2)}</h3>
          </div>
        </div>
        <div className="mt-6 text-center">
          <button
            onClick={handleFinalizeSale}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Finalizar Venta
          </button>
        </div>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Ventas Recientes</h2>
          {sales.map((sale, index) => (
            <div key={index} className="mb-4 bg-gray-100 p-4 rounded-lg">
              <h3 className="text-lg font-bold mb-2">Venta {index + 1}</h3>
              <ul>
                {sale.map((item, idx) => (
                  <li key={idx} className="mb-2">
                    <div className="bg-gray-200 p-4 rounded-lg">
                      <p><strong>Producto:</strong> {item.product}</p>
                      <p><strong>Cantidad:</strong> {item.quantity}</p>
                      <p><strong>Precio:</strong> ${item.price.toFixed(2)}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-2 p-2 bg-gray-200 rounded-lg text-center">
                <h4 className="text-lg font-bold">Total: ${sale.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2)}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sales;
